
<template>

<b-container class="">
  <b-row id="navbar" class="d-flex flex-row-reverse" style="min-height: 2rem; background-color: black !important;" >
  
  <b-button type="button" class="text-center ml-auto mt-4 connect-btn" variant="outline-primary" size="lg" v-if="!isConnect" v-on:click="connect()">Connect Wallet</b-button>
  <b-spinner v-if="isLoading" variant="warning" class="mt-4 ml-4 text-right" id="loading" label="Busy"></b-spinner>
  <p class="mt-4 h6 text-center ml-auto" id="walletaddress" v-if="isConnect">👋 {{ walletAddress }}</p>
  <a class="mt-4 h5 px-3 menulinks" v-if="isConnect" href="./gallery.html">🧐 <span class="">Gallery</span></a>
  <a class="mt-4 h5 px-3 menulinks" v-if="isConnect" href="./rewards.html">🍃 <span class="gradient menulink">My MSHZ</span></a>
  <a class="mt-4 h5 px-3 menulinks" v-if="isConnect" href="./mint.html">🍄 <span class="">Mint</span></a>
  </b-row>


<div class="" style="position: fixed; left: 0;">
<div id="stars"></div>
<div id="stars2"></div>

</div>

    <b-row class="header-row text-center mb-5">
      <b-col>
        <b-img src="./assets/logo.png" fluid alt="MoonShroomiz Logo"></b-img><br>
            <Countdown :date="end" @onFinish="finish()"></Countdown>
                    <p class="h3 pt-3 y">🍄 <strong class="gradient">MoonShroomiz</strong> Minting is now over !🍄</p>

           <p class="h2 gradient font-weight-bold glow p-3">{{ token }} / 500</p>
                   <p class="h2 cy font-weight-bold glow p-3">Get your MSHZ on <a class="mb" href="https://moonbeans.io/#/collections/moonshroomiz" target="_blank">MoonBeans marketplace !</a></p>
         <b-img-lazy class="pt-3 gif glow" v-if="!isConnect" src="./assets/gif.gif" fluid alt="MoonShroomiz Gif"></b-img-lazy><br>
        <span><a href="https://twitter.com/moonshroomiz" target="_blank"><b-img class="mx-2 glow rs" src="./assets/twitter.png" fluid alt="Twitter"></b-img></a>
        <a href="https://discord.com/invite/McdmemWqTX" target="_blank"><b-img class="mx-2 glow rs" src="./assets/discord.png" fluid alt="Discord"></b-img></a></span>
     
        <p class="h4 pt-3">Once upon a time, the <strong class="gradient">Shroomiz</strong> were living hidden in the deepest part of a magical forest.</p>

<p class="h4 pt-2">Living for ages in the woods, these magical creatures grew tired of having up to their very existence ignored by all.</p>

<p class="h4 pt-2">Following the call of the legendary Willy the Bold, they engaged on a fabulous journey to discover the outside world on the Kusama chain through <a href="" target="_blank" class="singular">Singular.</a></p>

<p class="h4 pt-2">Born to grow and to spread, the <strong class="gradient">Shroomiz</strong> soon started to release their spores in the atmosphere to colonize new territories.</p>

<p class="h4 pt-2 y">This is the story of Willy the Bold's magical spores and how they landed on the <span class="gradient font-weight-bold">Moonriver</span> chain, ready to colonize the Moon!</p>
<br>
          <b-img-lazy class="mx-4 pt-5 gif glow text-center" fluid src="./assets/gif.gif" alt="MoonShroomiz Gif"></b-img-lazy><br>
         <p class="h3 pt-3 p-4 cy">⭐ <span class="font-weight-bold glow">The MoonShroomiz : What are we?</span></p>
          <p class="h4 pt-2">Upon on landing on the Moon, the spores of Willy the Bold soon started to form mycelium and grew into <span class="gradient font-weight-bold">500</span> magnificent Boletus Edulis : <strong class="gradient">the MoonShroomiz</strong>.</p>
         <p class="h4 pt-2">Possessing amazing natural properties including <strong class="y">super fertile spores, an explosive growth speed and very strong anti-bacterial powers</strong>, <strong class="gradient">the MoonShroomiz</strong> were ready to keep growing and growing more!</p>
         <p class="h4 pt-2">Contrasting with the rather reserved and shy nature of their ancestors, <strong class="gradient">the MoonShroomiz</strong> were also wilder, crazier and bolder.<br>To put in another way, they were <strong class="y">TRUE degenerates.</strong></p>

<br>
  
            <b-modal v-if="isModalVisible" hide-footer id="modal" title="How strong is my MoonShroomiz?">
          <p class="h4 pt-3">Each <strong class="gradient">MoonShroomiz</strong> is unique and magical, but some are more potent than others.<br>There three criteria that will make for a strong <strong class="gradient">MoonShroomiz</strong>:<br></p><p><span class="h4 y">Spore Fertility (<b>SF</b>), Growth Speed (<b>GS</b>) and Anti-Bacterial Defense (<b>ABD</b>)</span></p>
         <p class="h5 pt-3">These criteria are ranked on a scale from 0 (<i>the weakest</i>) to 100 (<i>the strongest</i>).<br>The higher your NFT ranks in these aspects and the more advantage your <strong class="gradient">MoonShroomiz</strong> will have in our upcoming game !</p>
         <p class="h5 pt-3">The single most important attribute that will determine your <strong class="gradient">MoonShroomiz</strong> strength is its cap. There are four kinds of caps from the most common to the rarest:</p>
         <ul class="list-unstyled pt-3">
         <li>A <span style="color: #b76b26; font-weight: bold;">Classic Brown cap</span> gets a random value between +25 and +35 for SF, GS and ABD</li>
         <li>A <span style="color: #0f51ba;  font-weight: bold;">Rare Blue cap</span>  gets a random value between +40 and +55 for SF, GS and ABD</li>
         <li>An <span style="color: #a916be;  font-weight: bold;">Epic Purple cap</span> gets a random value between +60 and +75 for SF, GS and ABD</li>
         <li>A <span style="color: #f4be46;  font-weight: bold;">Legendary Golden cap</span> gets a random value between +80 and +90 for SF, GS and ABD</li>
         </ul>
        <p class="h5 pt-3">The stats of your <strong class="gradient">MoonShroomiz</strong> will also be enhanced if your NFT wears a necklace and/or possess an accessory.<br>Each of these attributes can increase your 3 stats by a maximum of 5 points.<br>(e.g : Magic wand : +3 SF / +2 GS / +1 ABD)</p>
      <br>
            </b-modal>


      <b-img-lazy class="mx-4 glow text-center" src="./assets/rarity.png" alt="MoonShroomiz Rarity"></b-img-lazy>
         <p class="h3 pt-3 p-4 cy">⚡ <span class="font-weight-bold glow">How rare is my MoonShroomiz?</span></p>
          <p class="h4 pt-2">The rarity of your <strong class="gradient">MoonShroomiz</strong> is mostly determined by its cap.</p>
         <p class="h5 pt-2">There are four kinds of caps :</p>
          <ul class="list-unstyled">
         <li>357 <span style="color: #b76b26; font-weight: bold;">Classic Brown cap</span> - 71.40% of the collection</li>
         <li>75 <span style="color: #0f51ba;  font-weight: bold;">Rare Blue cap</span>  -  15.00% of the collection</li>
         <li>57 <span style="color: #a916be;  font-weight: bold;">Epic Purple cap</span> - 11.40% of the collection with a Full Moon background</li>
         <li>11 <span style="color: #f4be46;  font-weight: bold;">Legendary Golden cap</span> - 2.20% of the collection with a Full Moon background</li>
         </ul>
         <p class="h5 pt-2">The rarest the cap of your <strong class="gradient">MoonShroomiz</strong> and the more likely it is to score high on these three criteria : <span class="y">Spore Fertility, Growth Speed and Anti-Bacterial Defense</span>.</p>
         <p class="h5 pt-2">Generally speaking, if your <strong class="gradient">MoonShroomiz</strong> has a colored cap, it will be pretty rare! On top of that, your NFT will possess some traits that are more or less rare.</p>
         <br>
      <br>
      <div class="pt-5">
      <p class="h3 pt-3 p-4 cy">🧙‍♂️ <span class="font-weight-bold glow">The MoonShroomiz Team</span></p>
  <b-card-group deck>
    <b-card class="bg-transparent" title="Mr Tin" img-src="https://gateway.pinata.cloud/ipfs/QmS3TbhWckGph3XjSJCn5tc8gPJHWiMWRzkwwVgZGBGDaq" img-alt="mShroomiz MrTin" img-top>
      <b-card-text>
        Mr Tin created the Shroomiz magical universe and designed their unique personalities. He’s in charge of the story-telling.
      </b-card-text>
        <!-- <small class="gradient font-weight-bold">MoonShroomiz #12</small> -->
    </b-card>

  <b-card class="bg-transparent" title="Tân" img-src="https://gateway.pinata.cloud/ipfs/QmQgqDbfWKwEVB72wJPNKNZvASS56x8zLufEyhBqcmgFa5" img-alt="mShroomiz Tân" img-top>
      <b-card-text>
       Tân is a young artist with a vivid imagination. He faithfully reproduced acutal mushrooms to give birth to the Shroomiz.
      </b-card-text>
       <!--  <small class="gradient font-weight-bold">MoonShroomiz #22</small> -->

    </b-card>

  <b-card class="bg-transparent" title="Marin" img-src="https://gateway.pinata.cloud/ipfs/QmTUeERnW6Er8RMkVxR4LBZZbK4p4YCNuFAJAzWmqR2Yua" img-alt="mShroomiz Marin" img-top>
      <b-card-text>
        Marin woke up one day with the idea of creating a series of cool and likeable little mushrooms. He’s our lead developer.
      </b-card-text>
    
        <!-- <small class="gradient font-weight-bold">MoonShroomiz #32</small> -->
      
    </b-card>
  </b-card-group>
</div>
      </b-col>
    </b-row>
          <b-row class="pt-5 mb-3 text-center d-flex justify-content-center">
        <b-col md="2" class="b-3">
          <h6 class="">🌟 <a class="cy flink title font-weight-bold" href="./index.html">Home</a></h6>
        </b-col>
        <b-col md="2" class="b-3">
          <h6 class="">🍄 <a class="cy flink title font-weight-bold" href="./mint.html">Mint</a></h6>
        </b-col>
        <b-col md="2" class="b-3">
          <h6 class="">🍃 <a class="cy flink title font-weight-bold" href="./spread.html">Spread</a></h6>
        </b-col>
        <b-col md="2" class="b-3">
          <h6 class="">🧐 <a class="cy flink title font-weight-bold" href="./gallery.html">Gallery</a></h6>
        </b-col>
        <b-col md="2" class="b-3">
          <h6 class="">💌 <a class="cy flink title font-weight-bold" href="mailto:shroomiznft@pm.me">Contact</a></h6>
        </b-col>
      </b-row>
      
      <b-row class="d-flex text-center justify-content-center mb-md-0 mb-4">
        <b-col md="8" sm="12" class="mt-5">
          <p style="lineHeight: '1.7rem'">The <strong class="gradient">MoonShroomiz</strong> are a unique collection made of 500 adorable Boletus Edulis.<br>Each NFT has various characteristics (include name, traits, rarity, spores fertility, growth speed, anti-bacterial defense) which will define your MoonShroomiz value.</p>
        </b-col>
      </b-row>
      <hr class="clearfix d-md-none rgba-white-light" style="margin: '10% 15% 5%'" />
      <b-row class="pb-3  flex-center text-center">
        <b-col md="12">
          <div class="mb-3">
       <a href="https://twitter.com/moonshroomiz" target="_blank"><b-img class="mx-2 glow rs" src="./assets/twitter.png" fluid alt="Twitter"></b-img></a>
        <a href="https://discord.com/invite/McdmemWqTX" target="_blank"><b-img class="mx-2 glow rs" src="./assets/discord.png" fluid alt="Discord"></b-img></a>
          </div>
          <p>🍄 <strong class="gradient">MoonShroomiz</strong> 🍄<br>
            Born to Grow</p>
             <p class="cy" style="cursor: pointer;" v-on:click="topFunction()">👆 Back to top</p>
        </b-col>
      </b-row>
  </b-container>



</template>


<script>
//import Header from './components/header.vue'
import { ethers } from "ethers";
import { abi, contractAddress } from "./conf.js";



export default {
  components:{
      
        //  'my-header': Header
     },
  name: 'App',
  data() {
    return {
      contractInstance: null,
      isLoading: false,
      add: '',
      isConnect: false,
      walletAddress: '',
      token: '',
      tokensOwned : '',
      ids : [],
      mintingCompleted : false,
      dismissCountDown: 0,
      dismissSecs: 5,
      amount : 0,
      tokendetail : {},
      images : [],
      provider: {},
      signer: {},
      blockNumber: {},
      gasPrice: {},
      overrides: {},
      w: {},
      chainId: '',
      isModalVisible: false,
      holder: 0,
    };
  },
  mounted() {


window.ethereum.on('accountsChanged', function (accounts) {
  console.log('Account changed')
  this.walletAddress = accounts[0];
  this.isConnect = false;
});

if (window.ethereum) {
  if (this.signer) {
      this.isConnect = true;
      console.log(this.signer)
      this.connect();
  }
}
 
  },
  methods: {
        finish() {
      console.log('finish');
    },
      showAlert() {
        this.dismissCountDown = this.dismissSecs
      },
    countDownChanged(dismissCountDown) {
        this.dismissCountDown = dismissCountDown
      },
  topFunction() {
  document.body.scrollTop = 0; // For Safari
  document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
},
showModal() {
  this.isModalVisible = true;
},
closeModal() {
setTimeout(() => {  this.isModalVisible = false; }, 2000);
  
},
    async refresh(provider, signer, contract) {
      let token = await contract.totalSupply()
      this.token = ethers.utils.formatUnits(token, 0)
      let connectedWallet = await signer.getAddress();
      this.walletAddress = connectedWallet;



      this.isLoading = false;

    },

    async connect() {

      this.isLoading = true;
      let provider = new ethers.providers.Web3Provider(window.ethereum, "any");
      let chainId = await provider.getNetwork()
      this.chainId = chainId
      if (this.chainId.chainId != 1285) {
      //alert('Please connect to MOVR Network (chain ID 1285)')
      alert('Please connect to MOVR Network (chain ID 1285)')
      this.switchNetwork();
      return
      }
    // Prompt user for account connections
      let wallets = await provider.send("eth_requestAccounts", []);
      console.log(wallets)
      let signer = provider.getSigner();

      const contract = new ethers.Contract(contractAddress, abi, signer);
      //let approve = await contract.setApprovalForAll(contractAddress, true);
      this.isConnect = true;
      this.refresh(provider,  signer, contract);
    },


  async switchNetwork() {
    const moonriverChainId = '0x505';
    let provider = window.ethereum;
  try {
    await provider.request({
      method: 'wallet_switchEthereumChain',
      params: [{ chainId: moonriverChainId}],
    });
    window.location.reload();
  }
catch (switchError) {
    // This error code indicates that the chain has not been added to MetaMask.
    if (switchError.code === 4902) {
     console.log("This network is not available in your metamask, please add it")
    }
    console.log(switchError)
    console.log("Failed to switch to the network")
  }
  },

  },
  
};

</script>

<style lang="css">
@import url('https://fonts.googleapis.com/css?family=Poppins:400,500');
@import './assets/style.css';
</style>
